import { Link } from 'react-router-dom';
import '../styles/header.css';

function Header() {
  return (
    <div className="header">
      <div className="fixed-header">
        <div className="header-content">
          <div>
            <Link to="/">
              <img
                src="/images/Logo_SleepDev_(on_black)-Tiny.png"
                alt="Logo SleepDev"
                className="logo"
                // width="70%"
              />
            </Link>
          </div>
          {/* <Link to="/learn-more">
            <div className="button-inverted">
              receive updates on the upcoming WhisperPT Releases
            </div>
          </Link> */}
          <center>
            <div className="bottom_justified">
              <div className="FAQ-flex-box-container">
                <Link to="/">
                  <div className="App-link">Our Technology</div>
                </Link>
                <Link to="/ObstructiveSleepApneaFAQ">
                  <div className="App-link">Obstructive Sleep Apnea FAQ</div>
                </Link>
                <Link to="/PositionalTherapyFAQ">
                  <div className="App-link">Positional Therapy FAQ</div>
                </Link>
                {/* <Link to="/Announcements">
                  <div className="App-link">Announcements</div>
                </Link> */}
              </div>
            </div>
          </center>
          <Link to="/AboutUs">
            <div className="App-link">about us</div>
          </Link>
        </div>
      </div>
      <div className="fixed-header-buffer"></div>
    </div>
  );
}
export default Header;
