import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Landing from './components/Landing';
import LandingOld from './components/Landing-OLD';
import LeadIn from './components/LeadIn';
import ExecutiveSummary from './components/executivesummary';
import ExecutiveSummaryWPTEar from './components/executive_summary_WPT-Ear';
import ExecutiveSummaryShort from './components/executive_summary_short';
import ExecutiveSummaryConfidential from './components/executive_summary_confidential';
import PositionalTherapyFAQ from './components/PositionalTherapyFAQ';
import WIP from './components/WIP';
import ObstructiveSleepApneaFAQ from './components/ObstructiveSleepApneaFAQ';
import SnoringFAQ from './components/SnoringFAQ';
import AboutUs from './components/AboutUs';
import Announcements from './components/Announcements';
import Footer from './components/Footer';
import Error from './components/Errors';
import DonutChart from './components/DonutChart';

function App() {
  const [mode, setMode] = useState('light');

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) =>
        onSelectMode(e.matches ? 'dark' : 'light')
      );

    // Setup dark/light mode for the first time
    onSelectMode(
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'light' //MS change to dark when its time to active dark/light mode detection
        : 'light'
    );

    // Dynamically add ChatBase chatbot script
    const script = document.createElement('script');
    script.src = 'https://www.chatbase.co/embed.min.js';
    script.defer = true;
    script.setAttribute('chatbotId', 'f-MMjGw2lfLJP9krsDX3P');
    script.setAttribute('domain', 'www.chatbase.co');
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const onSelectMode = (mode) => {
    setMode(mode);
    if (mode === 'dark') document.body.classList.add('dark-mode');
    else document.body.classList.remove('dark-mode');
  };

  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
          <Header />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/learn-more" element={<LeadIn />} />
            <Route
              path="/executive_summary_WPT-Ear"
              element={<ExecutiveSummaryWPTEar />}
            />
            <Route
              path="/executivesummary"
              element={<ExecutiveSummaryWPTEar />}
            />
            <Route
              path="/executive_summary"
              element={<ExecutiveSummaryWPTEar />}
            />
            <Route
              path="/executive_summary_short"
              element={<ExecutiveSummaryShort />}
            />
            <Route
              path="/executive_summary_confidential"
              element={<ExecutiveSummaryConfidential />}
            />
            <Route
              path="/PositionalTherapyFAQ"
              element={<PositionalTherapyFAQ />}
            />
            <Route
              path="/ObstructiveSleepApneaFAQ"
              element={<ObstructiveSleepApneaFAQ />}
            />
            <Route path="/SnoringFAQ" element={<SnoringFAQ />} />
            <Route path="/Landin_Old" element={<LandingOld />} />
            <Route
              path="/ObstructiveSleepApneaFAQ"
              element={<ObstructiveSleepApneaFAQ />}
            />
            <Route
              path="/PositionalTherapyFAQ"
              element={<PositionalTherapyFAQ />}
            />
            <Route path="/WIP" element={<WIP />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Announcements" element={<Announcements />} />
            <Route path="/SleepScore" element={<DonutChart />} />
            <Route path="/*" element={<Error />} />
          </Routes>
          <Footer />
        </header>
      </div>
    </BrowserRouter>
  );
}

export default App;
