import React, { useState } from 'react';
import '../styles/base.css';
import '../App.css';
import '../styles/donutChart.css';

const DonutChart = ({
  initialSleepScore = 78, // Default initial sleep score
  radius = 120,
  strokeWidth = 25,
  bgColor = '#004272',
  strokeColor = '#54aae9',
  bgImage = '/images/elements/wave_sleepScore_backdrop3.png',
}) => {
  const [sleepScore, setSleepScore] = useState(initialSleepScore); // State for sleepScore

  const normalizedRadius = radius - strokeWidth * 0.5;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (sleepScore / 100) * circumference;

  // Handler for input change
  const handleInputChange = (event) => {
    const value = Number(event.target.value);
    if (value >= 0 && value <= 100) {
      setSleepScore(value); // Update state only for valid inputs (0-100)
    }
  };

  return (
    <div>
      <div className="donut-chart-wrapper">
        {/* Image that will sit in the background */}
        <img src={bgImage} alt="Background" className="background-image" />
        {/* Donut Chart */}
        <div className="donut-chart-container">
          <svg height={radius * 2} width={radius * 2}>
            {/* Background circle */}
            <circle
              stroke="#3b3b3b"
              fill={bgColor}
              strokeWidth={strokeWidth + 5}
              r={normalizedRadius}
              cx={radius}
              cy={radius}
            />

            {/* Progress circle with rounded end */}
            <circle
              stroke={strokeColor}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={circumference + ' ' + circumference}
              strokeDashoffset={strokeDashoffset}
              r={normalizedRadius}
              cx={radius}
              cy={radius}
              style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
              strokeLinecap="round" // Adds a rounded end to the progress bar
            />
          </svg>
          <div className="donut-chart-text">{sleepScore}</div>
        </div>
      </div>
      {/* Input field to update sleepScore */}
      <div className="input-container">
        <label htmlFor="sleepScoreInput">
          Enter Your Sleep Score (0-100):{' '}
        </label>
        <input
          type="number"
          id="sleepScoreInput"
          value={sleepScore}
          onChange={handleInputChange}
          min="0"
          max="100"
        />
      </div>
    </div>
  );
};

export default DonutChart;
