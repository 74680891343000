import { Link } from 'react-router-dom';
import '../styles/FAQ.css';
import '../App.css';
import '../styles/about_us.css';
import Announcements150Nights from './Announcement150Nights';

function announcements() {
  return (
    <main className="page main-content gutters">
      Test announcements
      <br />
      Line2
      <Announcements150Nights />
    </main>
  );
}

export default announcements;
